import { template as template_3e5d270dc85d4004a41eb3a1ac14b693 } from "@ember/template-compiler";
const WelcomeHeader = template_3e5d270dc85d4004a41eb3a1ac14b693(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
