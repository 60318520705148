import { template as template_40a47ef84f4e4ea186357683b0379a7d } from "@ember/template-compiler";
const FKControlMenuContainer = template_40a47ef84f4e4ea186357683b0379a7d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
