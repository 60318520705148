import { template as template_a03e8e869d0744bd9c87b778d0cfbb0a } from "@ember/template-compiler";
const FKText = template_a03e8e869d0744bd9c87b778d0cfbb0a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
