import { template as template_11cc1c4aa459440a8760f99cfd8df08b } from "@ember/template-compiler";
const FKLabel = template_11cc1c4aa459440a8760f99cfd8df08b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
